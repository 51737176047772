.Secret {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ButtonContainer {
  margin-top: 30px;
}

.SecretHint {
  text-align: center;
  color: #959595;
}

.SecretBody {
  background-color: #cccccc;
  min-width: 500px;
  height: 125px;
  padding: 0 20px 0 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SecretText {
  white-space: pre-wrap;
  font-size: 16px;
  background-color: #ffffff00;
}
