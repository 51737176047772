@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

* {
  background-color: #fbfbfb;
  font-family: "Montserrat", sans-serif;
}

::selection {
  background: #ffb7b7; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #ffb7b7; /* Gecko Browsers */
}

.App {
  padding: 3em 5em 3em 5em;
}
