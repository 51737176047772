.Home {
  display: flex;
  justify-content: space-between;
}

.Hero {
  width: 50em;
}

.Heading {
  font-size: 48px;
}

.Subheading {
  font-size: 32px;
}

.ImageContainer {
  margin-top: 10em;
}
