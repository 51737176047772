.NavigationBarContainer {
  height: 3em;
  display: flex;
  justify-content: space-between;
}

.Logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
}

.Navigation {
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.Navigation p {
  padding: 2.5em;
  font-weight: bold;
}

.Main {
  padding-top: 5em;
}
