@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  background-color: #fbfbfb;
  font-family: "Montserrat", sans-serif;
}

::selection {
  background: #ffb7b7; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #ffb7b7; /* Gecko Browsers */
}

.App {
  padding: 3em 5em 3em 5em;
}

.NavigationBarContainer {
  height: 3em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Logo {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  font-size: 24px;
  font-weight: 700;
}

.Navigation {
  width: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.Navigation p {
  padding: 2.5em;
  font-weight: bold;
}

.Main {
  padding-top: 5em;
}

.Home {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Hero {
  width: 50em;
}

.Heading {
  font-size: 48px;
}

.Subheading {
  font-size: 32px;
}

.ImageContainer {
  margin-top: 10em;
}

.Secret {
  height: 50vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
}

.ButtonContainer {
  margin-top: 30px;
}

.SecretHint {
  text-align: center;
  color: #959595;
}

.SecretBody {
  background-color: #cccccc;
  min-width: 500px;
  height: 125px;
  padding: 0 20px 0 20px;
  border-radius: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.SecretText {
  white-space: pre-wrap;
  font-size: 16px;
  background-color: #ffffff00;
}

